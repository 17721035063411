import { AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Link as Anchor,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react";
import AddBasketButton, { sellableWithoutStockFunc } from "@components/AddBasketButton";
import FavoritesButton from "@components/FavoritesButton.component";
import Image from "@components/Image";
import { ImageCarousel } from "@components/ImageCarousel";
import MotionBox from "@components/MotionBox.component";
import ProductListComponent from "@components/ProductList";
import ProductCarouselComponent from "@components/ProductList/ProductCarousel.component";
import VideoComponent from "@components/Video";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import useRecommendations from "@hooks/useRecommendations";
import { useScrollDirection } from "@hooks/useScrollDirection";
import useTracking from "@hooks/useTracking";
import { PdpImage } from "@modules/ProductDisplay/PdpImage.component";
import { ProductDetails } from "@modules/ProductDisplay/ProductDetails.component";
import { SideBar } from "@modules/Sidebar/Sidebar";
import { useClientSideState } from "@zustand/clientSideState";
import { useLocale, useTranslations } from "next-intl";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import { useDebounce } from "rooks";

import MotionBoxComponent from "../MotionBox.component";
import { ProductDisplayType, ProductType } from "./ProductDisplay.model";

// @todo: this whole file needs to be refactored into subcomponents.
// It was underway, but unintended rerenders stalled the process.

const EmptyConfigurationLines = (count: number) => {
  let arr = []
  for (let i = 0; i < count; i++) {
    arr.push(ConfigurationLine(i, null, null))
  }
  return arr.map(i => i)
}

const ConfigurationLine = (iterator, label: string, value: string) => {
  return (
    <Flex key={iterator}>
      <Heading
        color="grey"
        as="h2"
        size="sm"
        h={"1.5rem"}
        minH={"1.5rem"}
        paddingRight="10px"
        noOfLines={2}
      >
        {label ? label + ":" : null}
      </Heading>
      <Heading
        as="h2"
        size="sm"
        h={"1.5rem"}
        minH={"1.5rem"}
        noOfLines={2}
      >
        {value}
      </Heading>
    </Flex>)
}

const QtyButton = ({ qty = 1, setQty }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      onChange(_, valueAsNumber) {
        setQty(valueAsNumber);
      },
      step: 1,
      defaultValue: qty,
      min: 1,
      max: 99,
      precision: 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="140px">
      <Button
        {...dec}
        variant="link"
        _hover={{ textDecoration: "none" }}
        fontSize="xx-large"
      >
        -
      </Button>
      <Input
        {...input}
        width="35px"
        height="35px"
        borderRadius={0}
        borderWidth="2px"
        borderColor="brand.gray.500"
        _hover={{ borderColor: "brand.gray.900" }}
        lineHeight="35px"
        paddingInline={0}
        textAlign="center"
        color="black"
        bgColor="white"
      />
      <Button
        {...inc}
        variant="link"
        _hover={{ textDecoration: "none" }}
        fontSize="xx-large"
      >
        +
      </Button>
    </HStack>
  );
};

const Overlay = () => (
  <ModalOverlay
    bg="blackAlpha.300"
    backdropFilter="blur(16px) grayscale(60%)"
  />
);

function ProductDisplayComponent(data: ProductDisplayType) {
  const {
    ProductName,
    Designer,
    Specs,
    VideoUrl,
    Usp1,
    Usp2,
    ShortDescription,
    Description,
    DescriptionHeader,
    Variants,
    MediaList,
    StoreLocatorPageUrl,
    RelatedProducts,
  } = data;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktopNav = useBreakpointValue({ base: false, lg: true });
  const [showVariants, setShowVariants] = useState(false);
  const [qty, setQty] = useState(1);
  const setQtyDebounced = useDebounce(setQty, 500);
  const [qtyLeadTime, setQtyLeadTime] = useState<string>("");
  const { asPath } = useRouter();
  const productPageDict = useTranslations("ProductPage");
  const productListDict = useTranslations("ProductList");
  const facetDict = useTranslations("Facet");
  const { trackViewItem } = useTracking();
  const [appData] = useAppData();
  const { marketData } = appData;
  const [hideBuyButton, setHideBuyButton] = useState<Boolean>(false);
  const { currentProductIds } = useClientSideState();
  const locale = useLocale();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<Overlay />);
  const [clickedImgNum, setClickedImgNum] = useState(0);
  const scrollDirection = useScrollDirection();
  const [minimizeNav, setMinimizeNav] = useState(false);
  const [navHeight, setNavHeight] = useState(130);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  if (!Variants) return;

  const focusedProduct = Variants?.filter(
    (variant) => variant.IsFocused
  ).shift();

  // If a product has a NotEnabledText other than "Not in stock",
  // such as "coming soon", we hide the price.
  const sellableWithoutStock = sellableWithoutStockFunc(
    focusedProduct.Stock,
    focusedProduct.SellWithoutStock
  );

  const calculateShowQtyButton = (focusedProduct: ProductType) => {
    if (focusedProduct.PurchaseNotEnabled) {
      return false;
    } else if (!sellableWithoutStock) {
      return false;
    }
    return true;
  };

  const calculateShowPrice = (focusedProduct: ProductType) => {
    if (focusedProduct.PurchaseNotEnabled) {
      if (focusedProduct.ShowPriceWhenPurchaseNotEnabled) {
        return true;
      }
      return false;
    }
    else if (!sellableWithoutStock) {
      return false;
    } else {
      return true;
    }
  };

  const [highlightedVariant, setHighlightedVariant] = useState(null);

  const { priceListData } = useClientSideState();

  const { recommend, finalProducts, error, loading } = useRecommendations({
    helloRetailRecomKey: RelatedProducts.HelloRetailStrategy,
    defaultProducts: RelatedProducts.Products,
  });
  useEffect(() => {
    if (priceListData && currentProductIds) {
      // do not execute the recommend until priselist has been async loaded
      recommend().catch(console.error);
    }
  }, [priceListData, currentProductIds]);

  useEffect(() => {
    if (focusedProduct) {
      trackViewItem(focusedProduct, ProductName, data.CanonicalUrl);
    }
  }, [focusedProduct]);

  // Temporarily disabled fetching lead time as Chs is not quite ready.
  useEffect(() => {
    const { Sku } = focusedProduct;
    if (!qty || !Sku) return;
    const fetchData = async () => {
      const data = await fetch("/api/getLeadTime", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locale,
          variantKey: Sku,
          marketId: marketData.MarketId,
          quantity: qty,
        }),
      });

      const json = await data.json();
      if (json.length) {
        setQtyLeadTime(json);
      }
    };

    fetchData().catch((error) => {
      console.error(error);
    });
  }, [qty, focusedProduct.Sku]);

  const productVariants = Variants?.filter(
    (variant) => !variant.IsFocused && variant.Images[0]?.Url
  ).map((variant) => ({ ...variant, Title: ProductName }));

  const ShippingInfo = qtyLeadTime
    ? [productPageDict("Hero.TextShippingInfoPrefix"), qtyLeadTime].join(" ")
    : [
      productPageDict("Hero.TextShippingInfoPrefix"),
      focusedProduct.FormattedShippingInformation,
    ].join(" ") || "";

  const handleOnClick = () => {
    setShowVariants(!showVariants);
  };

  useEffect(() => {
    isMobile && setShowVariants(false);
    setIsTouchDevice("ontouchstart" in window);
  }, [asPath]);

  const VariantButton = () =>
    productVariants.length > 11 && (
      <GridItem bgColor="brand.gray.300" position={"relative"}>
        <AbsoluteCenter axis="both" width="100%" height="100%">
          <Anchor
            onClick={handleOnClick}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textDecoration={"none !important"}
          >
            <Box display={"flex"} alignItems="center">
              <AddIcon boxSize={3} mr={1} />
              <Text fontSize="md" fontWeight="bold">
                {productVariants.length - 11}
              </Text>
            </Box>
          </Anchor>
        </AbsoluteCenter>
      </GridItem>
    );

  useEffect(() => {
    const fetchData = async () => {
      const edgeConfigRes = await fetch("/api/getEdgeConfig");
      const { hideBuyButton } = await edgeConfigRes.json();
      if (hideBuyButton) {
        setHideBuyButton(hideBuyButton);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (scrollDirection === "down") {
      setMinimizeNav(true);
      setNavHeight(isDesktopNav ? 60 : 40);
    }
    if (scrollDirection === "up") {
      setMinimizeNav(false);
      setNavHeight(isDesktopNav ? 130 : 40);
    }
  }, [scrollDirection]);

  const showPrice = calculateShowPrice(focusedProduct);
  const showQtyButton = calculateShowQtyButton(focusedProduct);

  const maxConfigurationLines = Math.max(...Variants.map(o => Object.keys(o.ConfigurationDictionary).length));
  const currentConfigurationLines = highlightedVariant
    ? Object.keys(highlightedVariant.ConfigurationDictionary).length
    : Object.keys(focusedProduct.ConfigurationDictionary).length;
  return (
    <Box>
      <Container pt={0} pb={10} px={{ base: 0, md: 4 }}>
        <Flex position="relative" flexDirection={{ base: "column", md: "row" }}>
          <Text top="3" left="4" position="absolute" zIndex={1}>
            {focusedProduct.Splash}
          </Text>
          {!isMobile && (
            <Modal isCentered isOpen={isOpen} onClose={onClose} size={"6xl"}>
              {overlay}
              <ModalContent p={6}>
                <ModalCloseButton />
                <ImageCarousel
                  images={[...focusedProduct?.Images, ...MediaList]}
                  controls
                  objectFit="contain"
                  aspectRatio="4:3"
                  sizes={`(max-width: 1280px) 66vw, 1200px`}
                  onReady={(splide) => {
                    splide.go(clickedImgNum);
                  }}
                />
              </ModalContent>
            </Modal>
          )}
          <Flex
            flexFlow="column nowrap"
            flexBasis="0"
            flexGrow="999"
            minWidth="40%"
          >
            {isMobile ? (
              <ImageCarousel
                images={focusedProduct?.Images}
                controls
                objectFit="contain"
                aspectRatio="1:1"
                sizes="(max-width: 768px) 100vw, 768px"
              />
            ) : (
              <>
                <SimpleGrid
                  columns={2}
                  position="relative"
                  spacing={{ base: 0, sm: 2, lg: 4 }}
                  pt={{ base: 2, md: 0 }}
                  pb={{ base: 2, md: 4 }}
                  gridAutoFlow="dense"
                >
                  <PdpImage
                    img={focusedProduct?.Images[0]}
                    priority
                    productImage
                    wide
                    sizes="(max-width: 768px) 100vw,
                           (max-width: 992px) 50vw,
                           (max-width: 1280px) 66vw,
                           900px"
                    onClick={() => {
                      setClickedImgNum(0);
                      setOverlay(<Overlay />);
                      onOpen();
                    }}
                  >
                    <IconButton
                      bottom={4}
                      left={4}
                      position="absolute"
                      zIndex={1}
                      onClick={() => {
                        setClickedImgNum(0);
                        setOverlay(<Overlay />);
                        onOpen();
                      }}
                      aria-label="View in full screen"
                      size="sm"
                      icon={<AiOutlineFullscreen size="20px" />}
                    />
                  </PdpImage>
                  {focusedProduct?.Images.map((img, idx) => {
                    if (idx === 0) return;
                    return (
                      <PdpImage
                        img={img}
                        productImage
                        key={`${idx}_${img.Url}`}
                        priority={idx < 2}
                        onClick={() => {
                          setClickedImgNum(idx);
                          setOverlay(<Overlay />);
                          onOpen();
                        }}
                      />
                    );
                  })}
                  {MediaList &&
                    MediaList.map((img, idx) => (
                      <PdpImage
                        img={img}
                        key={`${idx}_${img.Url}`}
                        onClick={() => {
                          setClickedImgNum(focusedProduct?.Images.length + idx);
                          setOverlay(<Overlay />);
                          onOpen();
                        }}
                      />
                    ))}
                </SimpleGrid>
                {VideoUrl && (
                  <VideoComponent
                    url={VideoUrl}
                    muted
                    loop
                    playing
                    playsinline
                  />
                )}
              </>
            )}
          </Flex>
          {/* Right pane */}
          <MotionBox
            display="flex"
            initial={{ top: { base: 40, lg: 130 } }}
            animate={
              isDesktopNav
                ? {
                  top: minimizeNav ? [130, 60] : [60, 130],
                }
                : { top: 40 }
            }
            transition={{ duration: 0.25 }}
            position={{ base: "relative", md: "sticky" }}
            maxHeight={{ base: "unset", md: `calc(100vh - ${navHeight}px)` }}
            flexFlow="column nowrap"
            flexGrow="1"
            flexBasis={{ base: "unset", md: "436px" }}
            alignSelf="start"
            overflowY="auto"
            pt={{ base: 4, md: 2 }}
            px={4}
            width="100%"
            sx={{
              "scrollbar-width": "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Flex justifyContent="space-between" flexFlow="row wrap">
              <Box pb={4}>
                {`${productPageDict("Hero.TextDesignerPrefix")} `}
                <NextLink href={Designer?.Link?.Url || "#"} passHref>
                  <Anchor>{Designer?.Name}</Anchor>
                </NextLink>
              </Box>
              <FavoritesButton showText id={focusedProduct.Sku} />
            </Flex>
            <Heading variant="heading3" fontWeight={700}>
              {(highlightedVariant && highlightedVariant.Title) ?? ProductName}
            </Heading>
            <Box marginTop={2} marginBottom={2}>
              {highlightedVariant ? Object.entries(highlightedVariant.ConfigurationDictionary).map(([key, value]: [string, string]) => {
                return ConfigurationLine(key, facetDict(key), value)
              }) : Object.entries(focusedProduct.ConfigurationDictionary).map(([key, value]: [string, string]) => {
                return ConfigurationLine(key, facetDict(key), value)
              })}
              {currentConfigurationLines < maxConfigurationLines ?
                EmptyConfigurationLines(maxConfigurationLines - currentConfigurationLines)
                : null
              }
            </Box>
            <Grid templateColumns="repeat(6, 1fr)" gap={3} mb={4}>
              {productVariants.length > 1 && (
                <GridItem bgColor="brand.gray.300" position={"relative"}>
                  <Box borderBottom="2px" borderColor="brand.gray.500">
                    <MotionBoxComponent
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      whileHover={{ scale: 1 }}
                      initial={{ scale: 0.9 }}
                    >
                      <NextLink href={focusedProduct.PageUrl} passHref>
                        <Anchor display="block" p={1}>
                          <Image
                            src={focusedProduct.Images[0].Url}
                            alt={focusedProduct.Images[0].AlternativeText || ""}
                            size="small"
                            aspectRatio={"5:6"}
                            objectFit={"contain"}
                            sizes={"200px"}
                          />
                        </Anchor>
                      </NextLink>
                    </MotionBoxComponent>
                  </Box>
                </GridItem>
              )}

              {productVariants
                .filter((variant) => variant.Sku != focusedProduct.Sku)
                .slice(0, 10)
                .map((variant) => (
                  <GridItem bgColor="brand.gray.300" position={"relative"}>
                    <Box>
                      <MotionBoxComponent
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        whileHover={{ scale: 1 }}
                        initial={{ scale: 0.9 }}
                        onMouseEnter={() => {
                          if (!isTouchDevice) {
                            setHighlightedVariant(variant);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!isTouchDevice) {
                            setHighlightedVariant(focusedProduct);
                          }
                        }}
                      >
                        <NextLink href={variant.PageUrl} passHref>
                          <Anchor display="block" p={1}>
                            <Image
                              src={variant.Images[0].Url}
                              alt={variant.Images[0].AlternativeText || ""}
                              size="small"
                              aspectRatio={"5:6"}
                              objectFit={"contain"}
                              sizes={"200px"}
                            />
                          </Anchor>
                        </NextLink>
                      </MotionBoxComponent>
                    </Box>
                  </GridItem>
                ))}
              {productVariants.length == 11 ? (
                <GridItem bgColor="brand.gray.300" position={"relative"}>
                  <Box>
                    <MotionBoxComponent
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      whileHover={{ scale: 1 }}
                      initial={{ scale: 0.9 }}
                      onMouseEnter={() => {
                        if (!isTouchDevice) {
                          setHighlightedVariant(
                            productVariants.filter(
                              (variant) => variant.Sku != focusedProduct.Sku
                            )[10]
                          );
                        }
                      }}
                      onMouseLeave={() => {
                        if (!isTouchDevice) {
                          setHighlightedVariant(focusedProduct);
                        }
                      }}
                    >
                      <NextLink
                        href={
                          productVariants.filter(
                            (variant) => variant.Sku != focusedProduct.Sku
                          )[10].PageUrl
                        }
                        passHref
                      >
                        <Anchor display="block" p={1}>
                          <Image
                            src={
                              productVariants.filter(
                                (variant) => variant.Sku != focusedProduct.Sku
                              )[10].Images[0].Url
                            }
                            alt={
                              productVariants.filter(
                                (variant) => variant.Sku != focusedProduct.Sku
                              )[10].Images[0].AlternativeText || ""
                            }
                            size="small"
                            aspectRatio={"5:6"}
                            objectFit={"contain"}
                            sizes={"200px"}
                          />
                        </Anchor>
                      </NextLink>
                    </MotionBoxComponent>
                  </Box>
                </GridItem>
              ) : (
                <VariantButton />
              )}
            </Grid>

            {marketData.PurchasesEnabled ? (
              <>
                {showPrice ? (
                  <Flex
                    justifyContent="space-between"
                    pt={4}
                    alignItems="flex-end"
                  >
                    {showQtyButton ? <QtyButton qty={qty} setQty={setQtyDebounced} /> : <Box></Box>}
                    <Flex flexDirection="column" alignItems="flex-end">
                      {focusedProduct.Price < focusedProduct.ListedPrice && (
                        <Text
                          fontSize="lg"
                          fontWeight="bold"
                          textDecoration="line-through"
                          marginY={1}
                        >
                          {[
                            focusedProduct.FormattedListedPrice,
                            marketData.Currency,
                          ].join(" ")}
                        </Text>
                      )}
                      {focusedProduct.FormattedPrice?.length > 0 && (
                        <Heading variant="heading3" fontWeight={700}>
                          {getPriceFormat(
                            focusedProduct.FormattedPrice,
                            marketData.Currency
                          )}
                        </Heading>
                      )}
                    </Flex>
                  </Flex>
                ) : (
                  <></>
                )}
                {hideBuyButton ? (
                  <></>
                ) : (
                  <AddBasketButton
                    Sku={focusedProduct.Sku}
                    Stock={focusedProduct.Stock}
                    SellWithoutStock={focusedProduct.SellWithoutStock}
                    PurchaseNotEnabled={focusedProduct.PurchaseNotEnabled}
                    NotEnabledText={focusedProduct.NotEnabledText}
                    ProductionDays={focusedProduct.ProductionDays}
                    IsPreOrder={focusedProduct.IsPreOrder}
                    qty={qty}
                    disabledLink={StoreLocatorPageUrl}
                    price={focusedProduct.Price}
                    minHeight="40px"
                  />
                )}
                <Flex
                  fontSize="small"
                  flexWrap="wrap"
                  justifyContent="center"
                  py={3}
                >
                  <Text px={2}>{Usp1}</Text>
                  <Text px={2}>{Usp2}</Text>
                  {showPrice ? <Text px={2}>{ShippingInfo}</Text> : null}
                </Flex>
              </>
            ) : (
              <Box mx="auto" mt={2}>
                <NextLink href={StoreLocatorPageUrl} passHref>
                  <Anchor>
                    {productPageDict("Hero.FindDealer")} <ArrowForwardIcon />
                  </Anchor>
                </NextLink>
              </Box>
            )}
            <ProductDetails
              ShortDescription={ShortDescription}
              Designer={Designer}
              Specs={Specs}
              LongDescriptionExists={!!Description}
              RelatedProducts={RelatedProducts}
              RelatedProductsFinal={finalProducts}
            />
          </MotionBox>
        </Flex>
        {isMobile && (
          <VStack display="block">
            {MediaList && (
              <ImageCarousel images={MediaList} controls sizes="100vw" aspectRatio="1:1" objectFit="cover" />
            )}
            {VideoUrl && (
              <VideoComponent url={VideoUrl} muted loop playing playsinline />
            )}
          </VStack>
        )}
        {Description ? <Box id="description" /> : null}
      </Container>
      {
        Description ? (
          <Container py={{ base: 4, md: 10 }} maxWidth="container.lg">
            {DescriptionHeader ? (
              <Heading pb={4}>{DescriptionHeader}</Heading>
            ) : null}
            <Text whiteSpace="pre-line">{Description}</Text>
          </Container>
        ) : null
      }
      {
        productVariants.length > 0 && (
          <Container py={10} className={"productVariants"}>
            <Heading variant="h1" py={4}>
              {productListDict("AllLinkViewProduct", {
                0: productVariants.length,
              })}
            </Heading>
            <ProductCarouselComponent products={productVariants} />
          </Container>
        )
      }
      {
        showVariants && productVariants.length > 0 && (
          <SideBar heading="Variants" handleOnClick={handleOnClick}>
            <ProductListComponent
              products={[
                ...productVariants.slice(10, productVariants.length),
                ...productVariants.slice(0, 10),
              ]}
              columns={isMobile ? 4 : 8}
              hideBuyButton
              showVariants={showVariants}
            />
          </SideBar>
        )
      }
    </Box >
  );
}

export default ProductDisplayComponent;
