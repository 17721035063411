import { PageDataType } from "@modules/PageBuilder/PageBuilder.model";
import React, { ReactElement, Suspense } from "react";
import type { BlocksModel } from "./Blocks.model";
import BodyCopyOneBlockModel from "./BodyCopyOneBlock";
import BodyCopyTwoBlockModel from "./BodyCopyTwoBlock";
import BodyGalleryBlockModel from "./BodyGalleryBlock";
import BodyMediaColorBlockModel from "./BodyMediaColorBlock";
import BodyVideoBlockModel from "./BodyVideoBlock";
import CaseListBlockModel from "./CaseListBlock";
import CategoryBlockModel from "./CategoryBlock/CategoryBlock.component";
import CopyBlockModel from "./CopyBlock";
import DesignerListBlockModel from "./DesignerListBlock";
import EnvironmentBlockModel from "./EnvironmentBlock";
import { StudioExploreBlock as StudioExploreBlockModel } from "./ExploreBlock";
import FlagshipStoresBlockModel from "./FlagshipStoresBlock/FlagshipStoresBlock.component";
import HTMLBlockModel from "./HTMLBlock/HTMLBlock.component";
import HeroBlockModel from "./HeroBlock";
import HeroMixedBlockModel from "./HeroMixedMediaBlock";
import HeroNoMediaBlockModel from "./HeroNoMediaBlock";
import HighlightedProductsBlockModel from "./HighlightedProductsBlock";
import HrCloudBlockModel from "./HrCloudBlock/HrCloudBlock.component";
import ImageGalleryBlockModel from "./ImageGalleryBlock";
import InPageNavigationBlockModel from "./InPageNavigationBlock";
import { StudioProcessBlock as StudioProcessBlockModel } from "./ProcessBlock";
import ProductCarouselBlockModel from "./ProductCarouselBlock";
import ProductDesignBlockModel from "./ProductDesignBlock";
import ProductGalleryBlockModel from "./ProductGalleryBlock";
import ProductInspirationBlockModel from "./ProductInspirationBlock";
import ProductListBlockModel from "./ProductListBlock";
import ProductPageSpecsBlockModel from "./ProductPageSpecsBlock";
import ShoppingBenefitsBlockModel from "./ShoppingBenefitsBlock";
import StoreLocatorBlockModel from "./StoreLocatorBlock";
import StudioContactBlockModel from "./StudioContactBlock";
import NewsletterSignupFormBlockModel from "./NewsletterSignUp/NewsletterSignupFormBlock.component";

const BlockTypes = {
  ImageGalleryBlockModel,
  BodyCopyOneBlockModel,
  BodyCopyTwoBlockModel,
  BodyGalleryBlockModel,
  HeroBlockModel,
  StudioExploreBlockModel,
  HeroMixedBlockModel,
  HeroNoMediaBlockModel,
  HighlightedProductsBlockModel,
  HrCloudBlockModel,
  HTMLBlockModel,
  ShoppingBenefitsBlockModel,
  ProductListBlockModel,
  BodyVideoBlockModel,
  CategoryBlockModel,
  CaseListBlockModel,
  BodyMediaColorBlockModel,
  CopyBlockModel,
  InPageNavigationBlockModel,
  ProductPageSpecsBlockModel,
  ProductDesignBlockModel,
  StoreLocatorBlockModel,
  DesignerListBlockModel,
  StudioProcessBlockModel,
  ProductGalleryBlockModel,
  ProductCarouselBlockModel,
  ProductInspirationBlockModel,
  StudioContactBlockModel,
  FlagshipStoresBlockModel,
  EnvironmentBlockModel,
  NewsletterSignupFormBlockModel
};

interface Props {
  block: BlocksModel;
  variant: PageDataType["variant"];
  pageKind: string;
}

function ComponentBlock({ block, variant, pageKind }: Props): ReactElement {

  // studio/.../[[...slug]] passes in variant="studio", this is used here to fetch the studio variant of a component.
  const Component =
    BlockTypes[`${variant}${block.Kind}`] || BlockTypes[block.Kind];

  // console.log("ComponentBlock", block.Kind)

  // Used during development to find blocks not yet implemented.
  if (!Component) {
    // return <MissingBlock kind={block.Kind} />;
    return <></>;
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {React.createElement(Component, { ...block, variant })}
    </Suspense>
  );
}

function BlocksComponent({ Content, variant, Kind }): ReactElement {
  if (!Content) return;
  return Content.map((block, key) => (
    <ComponentBlock key={key} block={block} variant={variant} pageKind={Kind} />
  ));
}

export default BlocksComponent;
