import {
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Select,
  VStack,
} from "@chakra-ui/react";
import { FormField } from "@components/FormField.component";
import MotionBox from "@components/MotionBox.component";
import RichTextComponent from "@components/RichText.component";
import { useAppData } from "@hooks/useAppData";
import { Field, Form, Formik } from "formik";
import { useAnimation } from "framer-motion";
import { useLocale, useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { useInViewRef } from "rooks";

function NewsletterSignupFormBlockModel() {
  const animationControls = useAnimation();
  const [inViewRef, inView] = useInViewRef();
  const locale = useLocale();
  const [emailSent, setEmailSent] = useState(false);
  const [
    {
      marketData: { MarketId },
    },
  ] = useAppData();

  const [
    {
      marketData: { NewsletterSettings },
    },
  ] = useAppData();
  const dict = useTranslations("NewsletterSignup");
  const validationDict = useTranslations("Shared.Validation");
  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["10%", "0%"],
    });
  }, [inView]); // eslint-disable-line

  function validateEmail(value) {
    let error;
    if (
      !value ||
      value === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      error = validationDict("ErrorInvalidEmail");
    }
    return error;
  }

  function validateCountry(value) {
    let error;
    console.log(value);
    // if (
    //   !value ||
    //   value === "" ||
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    // ) {
    //   error = validationDict("ErrorInvalidEmail");
    // }
    return error;
  }

  function validateEmpty(value) {
    let error;
    if (!value || value === "") {
      error = validationDict("ErrorEmptyField");
    }
    return error;
  }

  function validateTerms(value) {
    let error;
    if (!value) {
      error = validationDict("ErrorTerms");
    }
    return error;
  }

  const CustomerTypeFunc = (privateCust, businessCust) => {
    if (privateCust && !businessCust) {
      return "private";
    }
    if (!privateCust && businessCust) {
      return "business";
    }
    if (privateCust && businessCust) {
      return "private,business";
    }
    if (!privateCust && !businessCust) {
      return "";
    }
  };

  function sendEmail(data) {
    const newData = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      CustomerTypes: CustomerTypeFunc(
        data.customerTypePrivate,
        data.customerTypeBusiness
      ),
      CustomerCountry: data.CustomerCountry,
      Culture: locale,
      Source: "signup",
      MarketId: MarketId,
    };
    setEmailSent(true);
    fetch("/api/signup", {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    })
      .then((res) => {
        console.log("Response received");
        if (res.status === 200) {
          console.log("Response succeeded!");
          setEmailSent(true);
        }
      })
      .catch((error) => {
        console.log("An error occured: ", error);
      });
  }

  return (
    <Container
      ref={inViewRef}
      maxW="container.sm"
      paddingTop={10}
      paddingBottom={12}
      paddingX={{ base: 4, lg: 20 }}
    >
      <MotionBox
        initial={{ translateY: "100%", opacity: 0 }}
        animate={animationControls}
        height="100%"
      >
        {!emailSent ? (
          <Formik
            initialValues={{
              FirstName: "",
              LastName: "",
              Email: "",
              CustomerCountry: "",
              customerTypePrivate: false,
              customerTypeBusiness: false,
              terms: false,
            }}
            validateOnChange
            onSubmit={(values, actions) => {
              setTimeout(() => {
                sendEmail(values);
                actions.setSubmitting(false);
                actions.resetForm({
                  values: {
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    CustomerCountry: "",
                    customerTypePrivate: false,
                    customerTypeBusiness: false,
                    terms: false,
                  },
                });
              }, 1000);
            }}
          >
            {({
              handleSubmit,
              isValid,
              dirty,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Heading
                    as="h2"
                    size="lg"
                    color="gray.600"
                    textAlign="center"
                    p={{ base: 2, md: 4 }}
                  >
                    {dict("HeadingNotSubscribed")}
                  </Heading>
                  <Divider />
                  <VStack spacing={2}>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      gap={6}
                      mt={4}
                    >
                      <FormField
                        name="customerTypePrivate"
                        component={Checkbox}
                        type="checkbox"
                        label={NewsletterSettings?.Segments[0].Name}
                      />
                      <FormField
                        name="customerTypeBusiness"
                        component={Checkbox}
                        type="checkbox"
                        label={NewsletterSettings?.Segments[1].Name}
                      />
                    </Flex>

                    <FormField
                      name="FirstName"
                      label={dict("InputPlaceholderFirstName")}
                      placeholder={dict("InputPlaceholderFirstName")}
                      validate={validateEmpty}
                      autoComplete="given-name"
                    />

                    <FormField
                      name="LastName"
                      label={dict("InputPlaceholderLastName")}
                      placeholder={dict("InputPlaceholderLastName")}
                      validate={validateEmpty}
                      autoComplete="family-name"
                    />

                    <FormField
                      name="Email"
                      type="email"
                      label="Email"
                      placeholder={dict("InputPlaceholderEmail")}
                      validate={validateEmail}
                      autoComplete="email"
                    />

                    <Field name="country" validate={validateCountry}>
                      {({ field, form }) => (
                        <Select
                          name="CustomerCountry"
                          id="CustomerCountry"
                          onChange={field.onChange}
                          placeholder={dict("InputSelectLanguage")}
                          borderColor="blackAlpha"
                          focusBorderColor="blackAlpha"
                          variant="outline"
                          borderRadius={0}
                        >
                          {NewsletterSettings?.Countries.map((country: any) => (
                            <option
                              key={country.Value}
                              value={country.Value}
                              id="countryOption"
                            >
                              {country.Name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Field>

                    <Field name="terms" validate={validateTerms}>
                      {({ form, field }) => (
                        <FormControl
                          isInvalid={form.errors[field.name]}
                          isRequired={true}
                        >
                          <Checkbox
                            id="terms"
                            name="terms"
                            onChange={(e) =>
                              setFieldValue("terms", e.target.checked)
                            }
                          >
                            <RichTextComponent
                              text={NewsletterSettings?.Consent}
                            />
                          </Checkbox>
                          <FormErrorMessage>
                            {form.errors[field.name]}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </VStack>
                  <Flex justifyContent="center" alignItems="center">
                    <Button
                      isDisabled={!isValid || !dirty}
                      isLoading={isSubmitting}
                      type="submit"
                      fontSize="14px"
                      fontWeight={500}
                      mt={12}
                    >
                      {dict("ButtonSignup")}
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <Heading
              as="h2"
              size="lg"
              color="gray.600"
              textAlign="center"
              p={{ base: 2, md: 4 }}
            >
              {dict("HeadingSubscribed")}
            </Heading>
          </Flex>
        )}
      </MotionBox>
    </Container>
  );
}

export default NewsletterSignupFormBlockModel;
